<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'

import { Alert } from '@epostbox/ui/alert'
import { Button } from '@epostbox/ui/button'
import { FormField } from '@epostbox/ui/form'
import { Input } from '@epostbox/ui/input'
import { CardWrapper } from '@epostbox/ui/organisms/card-wrapper'

import { Email } from '@composables/schemas'
import { useSaml } from '@composables/use-saml'

const form = useForm({
  validationSchema: toTypedSchema(Email),
  initialValues: {
    email: undefined,
  },
})

const { login, error, isPending } = useSaml()

const onSubmit = form.handleSubmit(async values => {
  await login(values)
})
</script>

<template>
  <CardWrapper :title="$t('auth.flow.login.title')" :description="$t('auth.flow.sso.description')">
    <Alert v-if="error" variant="destructive" class="mb-4" :description="$t(`common.error.${error.name}`)" />
    <form novalidate @submit.prevent="onSubmit">
      <FormField v-slot="{ field }" :form="form" name="email">
        <Input type="email" :label="$t('auth.field.email.label')" autocomplete="email" v-bind="field" />
      </FormField>
      <div class="pt-2">
        <Button variant="primary" type="submit" size="lg" class="w-full" :loading="isPending" data-e2e="login-sso">
          {{ $t('auth.button.submit_sso') }}
        </Button>
        <Button as="router-link" to="/" variant="link" size="md" class="mx-auto mt-4 w-max" data-e2e="login">
          <INolasArrowLeft class="mr-1.5 h-5 w-5" />
          {{ $t('auth.button.back') }}
        </Button>
      </div>
    </form>
  </CardWrapper>
</template>
